/*--------------------------
    Header Styles  
--------------------------*/

.edu-header {
    .logo {
        a {
            display: block;
            height: 118px;
            line-height: 118px;
            transition: min-height 1s ease !important;
            img {
                max-height: 35px;
            }
            @media #{$smlg-device} {
                height: 80px;
                line-height: 80px;
            }
        }
    }

    &.disable-transparent {
        .logo {
            a {
                height: 90px;
                line-height: 90px;
            }
        }
    }

    &.header-relative {
        position: relative;
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background: transparent;
        backdrop-filter: inherit;
        z-index: 9;
    }

    &.header-sticky {
        transition: height .5s ease-in;
        max-width: 100%;
        z-index: 99;
        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            background-color: var(--color-white) !important;
            width: 100%;
            height: 80px;
            animation: headerSticky .95s ease forwards;
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
            z-index: 10;
            .logo {
                a {
                    height: 80px;
                    line-height: 80px;
                }
            }
        }
    }
}


/*------------------------
    Header Style One  
--------------------------*/
    
.header-default {
    max-width: 100%;
    background-color: var(--color-white);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;
   
    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

/*------------------------
    Header Style Two  
--------------------------*/
.header-style-2 {
    padding: 0 200px;
    
    @media #{$laptop-device} {
        padding: 0 30px;
    }
    
    @media #{$lg-layout} {
        padding: 0 30px;
    }

    @media #{$md-layout} {
        padding: 0 30px;
    }

    @media #{$sm-layout} {
        padding: 0 15px;
    }

    .header-menu-bar {
        display: flex;
        align-items: center;
    }
}

.white-box-icon {
    height: 50px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    outline: none;
    color: var(--color-primary);
    border: 0 none;
    padding: 0;
    background: var(--color-white);
    display: block;
    font-size: 16px;
    @media #{$sm-layout} {
        height: 40px;
        line-height: 40px;
        width: 40px;
    }
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

/*------------------------
    Header Language Switcher
--------------------------*/

.language-switcher .languageButton {
    border: 0;
    outline: 0;
    background: none
}

.language-switcher .switchButton {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 1em;
    color: var(--color-dark);
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    border: none;
    background: transparent;
}

.language-switcher img {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%
}

.language-switcher .language-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700
}

@media(max-width: 899px) {
    .language-switcher .language-menu {
        position:absolute;
        top: 56px;
        right: 0;
        left: 0;
        z-index: 5;
        display: none;
        margin-top: 5px;
        padding: 6px 0;
        background-color: var(--color-extra10)
    }

    .language-switcher.open .language-menu {
        display: block
    }
}

@media(min-width: 900px) {
    .language-switcher {
        position:relative
    }

    .language-switcher .languageButton {
        display: flex;
        gap: .125em;
        align-items: center
    }

    .language-switcher .languageButton:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hldnJvbi1kb3duIj48cG9seWxpbmUgcG9pbnRzPSI2IDkgMTIgMTUgMTggOSI+PC9wb2x5bGluZT48L3N2Zz4=);
        transition: all .3s ease-in-out
    }

    .language-switcher .switchButton {
        transition: all .3s ease-in-out
    }

    .language-switcher .switchButton:hover {
        background-color: var(--color-extra10)
    }

    .language-switcher .language-menu {
        position: absolute;
        right: 0;
        z-index: 5;
        display: none;
        width: 180px;
        margin-top: 5px;
        padding: 6px 0;
        border-radius: 8px;
        background-color: var(--color-white);
        box-shadow: 0 3px 12px rgba(1,5,37,.09),0 0 1px rgba(1,5,37,.24)
    }

    .language-switcher.open .languageButton:after {
        transform: rotate(180deg)
    }

    .language-switcher.open .language-menu {
        display: block
    }
}